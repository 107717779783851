<template>
  <div class="content_box theme_bg">
      <TianQiYuBao :position="position" ref="TianQiYuBao"></TianQiYuBao>
  </div>
</template>

<script>
import TianQiYuBao from '@/views/TianQiYuBao/TianQiYuBao.vue'
export default {
  components: {
    TianQiYuBao
  },
  name: "radarChart",
  data() {
    return {
      position:null,
    }
  },
  created() {
    // 获取 页面url经纬度
    const { lng, lat } = this.$route.query;
    this.position = { lng:Number(lng),lat:Number(lat)  }
  },
  mounted() {

  },
  methods: {


  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
</style>